import { captureException } from '@sentry/nextjs';
import { formatInTimeZone } from 'date-fns-tz';
import { enUS } from 'date-fns/locale';
import { GetServerSideProps } from 'next';

import client from '@/apollo-client';
import SwipeCard from '@/components/Landing/LandingSection2/SwipeCard';
import Layout from '@/components/Layout';
import Landing from '@/components/Pages/Landing';
import {
  FeaturedEventsDocument,
  FeaturedEventsQuery,
  FeaturedEventsQueryVariables,
} from '@/generated/graphql';

const LandingPage = (props: {
  events: NonNullable<FeaturedEventsQuery['featured']['events']>;
}) => {
  const { events } = props;

  const cards: SwipeCard[] = events.map((event) => ({
    imageUrl: event.imgUrl,
    venueName: event.venue.name,
    eventTitle: event.title,
    itinerary: formatInTimeZone(
      event.startDateTime,
      event.venue.timezone,
      `EEE MMM dd, hh:mmaa zzz`, // Sun Apr 10, 10:00PM EST
      { locale: enUS },
    ),
    eventId: event.id,
  }));

  return (
    <Layout headerType={`static`}>
      <Landing cards={cards}></Landing>
    </Layout>
  );
};

export const getServerSideProps: GetServerSideProps = async () => {
  try {
    const { data } = await client.query<
      FeaturedEventsQuery,
      FeaturedEventsQueryVariables
    >({
      query: FeaturedEventsDocument,
    });

    return {
      props: { events: data.featured.events ?? [] },
    };
  } catch (error) {
    captureException(error);

    return {
      redirect: {
        destination: `/500`,
        permanent: false,
      },
    };
  }
};

export default LandingPage;
